<template>
  <DelayHydration>
    <HeaderMain
      :home-link="homeLink"
      :theme-color="themeColor"
      :translation="translations[translationKey]"
    >
      <template #desktop>
        <NavDesktop
          :main-navigation="mainNavigation"
          :button-navigation="formattedButtonNavigation"
          :social-navigation="socialNavigation"
          :locale-navigation="localeNavigation"
          :is-mobile="isMobile"
          :translation="translations[translationKey]"
        />
      </template>

      <template #mobile>
        <NavMobile
          :main-navigation="mainNavigation"
          :button-navigation="formattedButtonNavigation"
          :social-navigation="socialNavigation"
          :locale-navigation="localeNavigation"
          :home-link="homeLink"
          :is-mobile="isMobile"
          :translation="translations[translationKey]"
        />
      </template>
    </HeaderMain>
  </DelayHydration>
</template>

<script setup lang="ts">
import type { T3Link } from '@t3headless/nuxt-typo3'
import {
  computed,
  defineAsyncComponent,
  onMounted,
  onUnmounted,
  ref
} from 'vue'

import { useCurrentLocale } from '#imports'

import type { HeaderComponentProps } from '~/types'
import type { UiButtonProps } from '~ui/types'

import translations from './translations.json'

const translationKey = useCurrentLocale<typeof translations>()

const HeaderMain = defineAsyncComponent(
  () => import('~ui/components/Base/HeaderMain/HeaderMain.vue')
)
const NavDesktop = defineAsyncComponent(
  () => import('~ui/components/Ui/Navigation/NavDesktop/NavDesktop.vue')
)
const NavMobile = defineAsyncComponent(
  () => import('~ui/components/Ui/Navigation/NavMobile/NavMobile.vue')
)

const props = defineProps<HeaderComponentProps>()

const mainNavigation = computed(() =>
  props.navigation.filter((link) => !buttonNavigation.value.includes(link))
)
const buttonNavigation = computed(() =>
  props.navigation.filter((link) => link.buttonLabel && !link.children)
)

const formattedButtonNavigation = computed(() => {
  return buttonNavigation.value.map(({ buttonLabel, link, target }) => ({
    content: buttonLabel,
    link: { href: link, target } as T3Link,
    variant: 'solid-typo3-orange',
    noWrap: true
  })) as Array<UiButtonProps>
})

const isMobile = ref(false)
const resizeHandler = () => {
  isMobile.value = window.innerWidth < 1024
}
onMounted(() => {
  window.addEventListener('resize', resizeHandler)
})
onUnmounted(() => {
  window.removeEventListener('resize', resizeHandler)
})
</script>
